.text_field__container {
    display: flex;
    width: 100%;
}

.text_field__container--space {
    margin-top: 1.5rem;
}

.top {
    flex-direction: column;
    gap: 10px;
}

.left {
    flex-direction: row;
    justify-content: space-between;
}

.text_field__label {
    font-size: 1rem;
    font-weight: 500;
    color: #111928;
    white-space: nowrap;
}
