.sidebar__logo_container {
    padding: 12px 30px 30px 30px;
}

.sidebar__logo_container.short {
    padding: 12px 6px 30px 7px;
}

.sidebar_line {
    border: 1px solid #dfe4ea;
    width: 80%;
    margin-right: 50%;
    margin-top: 15px;
}

.sidebar_container {
    background-color: #ffffff;
    height: 100vh;
    display: flex;
    flex-direction: column;
    box-shadow: 0 10px 20px 0 rgba(92, 115, 160, 0.07);
}

.sidebar_content {
    display: flex;
    flex-direction: column;
    flex: 1;
}
