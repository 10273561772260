.user_menu_container {
    padding: 18px 22px;
    display: flex;
    align-items: center;
}

.user_menu_titles {
    padding: 0 24px 0 8px;
    text-align: left;
}

.user_menu__name {
    font-size: 1rem;
    font-weight: 500;
    color: #111928;
}

.user_menu__role {
    color: #637381;
    font-size: 0.875rem;
    font-weight: 400;
}

.user_menu__RA > button:hover {
    background-color: transparent;
}

.user_menu__RA > button {
    padding: 0;
}
