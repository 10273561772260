.page_container {
    height: 100vh;
    width: 100vw;
    background-color: #f7f8fa;
}

.login_container {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 60px 40px 60px;
    background-color: #ffffff;
    border-radius: 0.5rem;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 520px;
    height: 484px;
}

.login_logo {
    width: 280px;
}

.login_form {
    display: flex;
    flex-direction: column;
    margin: 40px 0 35px 0;
    gap: 22px;
}

.login_form__input {
    padding: 12px 16px 12px 20px;
    font-size: 1rem;
    border-radius: 0.375rem;
    border: 1px solid var(--Stroke, #dfe4ea);
    width: 400px;
}

.login_form__input::placeholder {
    color: #9ca3af;
}

.login_form__input:active {
    border: 1px solid var(--Primary-Color, #3758f9);
}

.login_from__login_button {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    color: #ffffff;
    background-color: #3758f9;
    border: none;
    border-radius: 0.375rem;
    padding: 13px 0;
}

.login_from__login_button:hover {
    cursor: pointer;
}

.login__forgot_password {
    font-size: 1rem;
    color: #111928;
    background-color: transparent;
    border: none;
}

.login__forgot_password:hover {
    cursor: pointer;
}

.diia-qrcode {
    width: 400px;
}

.login-diia-info {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    font-family: Inter;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 40px;
}

.login-diia-qr-button,
.login-diia-refresh {
    display: inline-block;
    background-color: transparent;
    border: 1px solid #2fa9a6;
    border-radius: 0.375rem;
    padding: 13px 28px 13px 28px;
    width: 163px;
    height: 50px;
    margin-bottom: 170px;
}

.login-diia-qr-button:hover,
.login-diia-refresh:hover {
    cursor: pointer;
    background-color: #e0e0e0;
}

.login-diia-button-container,
.qr-code {
    display: flex;
    justify-content: center;
}

.login-diia-refresh {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    text-decoration: none;
    text-align: center;
    color: #2fa9a6;
    padding: 0;
    margin-bottom: 0;
}

.countdown {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 11px;
    font-weight: 600;
}
