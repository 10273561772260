#main-content {
    padding: 79px 0 0 0;
    background: #f1f1f1;
}

body {
    margin: 0;
    padding: 0;
    font-family: Inter, 'Segoe UI', Arial, sans-serif;
}
