.toggle-button {
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: url(../../../../assets/chevron-toggle-off.svg) white no-repeat center;
}

.Mui-checked .toggle-button {
    background: url(../../../../assets/chevron-toggle-on.svg) white no-repeat center;
}
