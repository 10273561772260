.checkbox_icon {
    width: 20px;
    height: 20px;
    border-radius: 6px;
    background-color: #ffffff;
    border: 1px solid #dfe4ea;
}

.checkbox_icon_checked {
    width: 20px;
    height: 20px;
    border-radius: 6px;
    background: url(../../../../assets/chevron-checkbox.svg) #2fa9a6 center no-repeat;
    border: 1px solid #2fa9a6;
}
